  import {Component, Inject, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
    title = 'SENADIS PRO - FUNCIONARIOS';

  constructor(@Inject(DOCUMENT) private document: Document, private render: Renderer2) {
  }

  switchMode(isDark: boolean): void {
    const hostClass = (isDark) ? 'my-dark-theme' : '';
    this.render.setAttribute(this.document.body, 'class', hostClass);
  }
}
