import {Injectable, Injector} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {Store} from '@ngrx/store';
import {StartLoading, StopLoading} from '../../util/store/counter.actions';
import {catchError, filter, take, switchMap, distinctUntilChanged, finalize} from 'rxjs/operators';
import {LocalStorageService} from '../../util/services/local-storage/local-storage.service';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;
  private tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private countRequest = 0;

  constructor(
    private injector: Injector,
    private localStorage: LocalStorageService,
    private store: Store<{ count: number }>,
    private tokenManager: AuthService
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (!this.countRequest) {
      this.store.dispatch(StartLoading());
    }
    this.countRequest++;

    if (!this.needsToken(request)) {
      return next.handle(request).pipe(
        finalize(() => {
          this.countRequest--;
          if (!this.countRequest) {
            this.store.dispatch(StopLoading());
          }
        })
      );
    }

    const token = this.localStorage.getTokenFromStorage();

    if (!token) {
      return next.handle(request)
        .pipe(
          finalize(() => {
            this.countRequest--;
            if (!this.countRequest) {
              this.store.dispatch(StopLoading());
            }
          })
        );
    }

    const tokenizedRequest = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
    this.localStorage.setLoading(false);


    return next.handle(tokenizedRequest)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            /* Se verifica si el Token ha vencido */
            return this.handle401Error(tokenizedRequest, next);
          } else {
            if (error.status === 400) {
              /* En caso de que el REFRESH TOKEN haya vencido, le envía al login. Si no es el caso, maneja el error normalmente */
              return this.handle400Error(error, tokenizedRequest.url);
            } else {
              /* Si es un error cualquiera viene por aquí*/
              return throwError(error);
            }
          }
        }),
        finalize(() => {
          this.countRequest--;
          if (!this.countRequest) {
            this.store.dispatch(StopLoading());
          }
        })
      );
  }

  public needsToken(req: HttpRequest<unknown>): boolean {
    return !req.url.includes('login') ||
      !req.url.includes('parametrosLogin') ||
      !req.url.includes('passwords');
  }

  handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    /* Se agrega el método distinctUntilChanged para evitar que haya iteraciones excesivas en los switchMap */
    if (this.isRefreshingToken) {
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        distinctUntilChanged(),
        switchMap(token => {
          return next.handle(this.addNewAccessTokenToHeaders(request, token));
        }));

    } else {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);
      return this.tokenManager.renewAccessToken().pipe(
        distinctUntilChanged(),
        switchMap((token: any) => {
          this.isRefreshingToken = false;
          this.tokenSubject.next(token.data.access_token);
          return next.handle(this.addNewAccessTokenToHeaders(request, token.data.access_token, token.data.refresh_token));
        })
      );
    }
  }

  addNewAccessTokenToHeaders(req: HttpRequest<any>, token: string, newRefreshToken: string = ''): HttpRequest<any> {
    req = req.clone({});

    this.injector.get(AuthService).setToken(token);

    if (newRefreshToken !== '') {
      this.injector.get(AuthService).setRefreshToken(newRefreshToken);
    }

    return req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  handle400Error(error: HttpErrorResponse, requesUrl: any) {
    if (error.status === 400 && requesUrl.includes('refreshToken')) {
      this.isRefreshingToken = false;
      this.tokenManager.goToLogin();
    }
    return Observable.throw(error);
  }
}
