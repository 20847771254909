import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoginService} from '../services/login.service';
import {ToastrService} from 'ngx-toastr';
import {ErrorService} from '../../util/services/error-service/error.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-recuperar-contrasenha',
  templateUrl: './recuperar-contrasenha.component.html',
  styleUrls: ['./recuperar-contrasenha.component.sass']
})
export class RecuperarContrasenhaComponent implements OnInit {

  @Input() token: string
  @Input() parametrosIniciales: any;
  newAccessToken: any;
  recuperarForm: FormGroup;
  @Output() hide: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private loginService: LoginService, private toastr: ToastrService,
              private errorService: ErrorService, private fB: FormBuilder) {
  }

  ngOnInit(): void {
    this.loadForm();
  }

  verifyToken() {
    this.loginService.verificarTokenChangePassword(this.token).subscribe((response: any) => {
      if (response.success) {
        this.newAccessToken = response.data;
      } else {
        this.toastr.error(response.errors[0], 'Error de Validación');
        this.hide.emit(true);
      }
    }, err => {
      this.errorService.handleError(err);
      this.hide.emit(true);
    }, () => {
      this.recuperarForm.get('token').setValue(this.newAccessToken.token);
    });
  }

  loadForm() {
    this.recuperarForm = this.fB.group({
      email: [undefined, [Validators.email, Validators.required]],
      token: [undefined, [Validators.required]],
      password: [undefined, [Validators.minLength(+this.parametrosIniciales.MINPASSWORD.MINPASSWORD), Validators.maxLength(+this.parametrosIniciales.MAXPASSWORD.MAXPASSWORD), Validators.required]],
      password_confirmation: [undefined, [Validators.minLength(+this.parametrosIniciales.MINPASSWORD.MINPASSWORD), Validators.maxLength(+this.parametrosIniciales.MAXPASSWORD.MAXPASSWORD), Validators.required]]
    });
    this.verifyToken();
  }

  actualizarPassword() {
    if (this.recuperarForm.controls.password.value !== this.recuperarForm.controls.password_confirmation.value) {
      this.toastr.error('Las contraseñas no coinciden', 'Error');
    } else {
      this.loginService.recuperarPassword(this.recuperarForm.value).subscribe((response) => {
          this.toastr.success('La contraseña ha sido actualizada correctamente', 'Actualizar contraseña');
        },
        error => {
          this.errorService.handleError(error);
        }, () => {
          this.hide.emit(true);
        });
    }
  }
}
