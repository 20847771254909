<div class="bg-zinc-600 p-9">
  <mat-card fxFlex class="overflow-hidden overflow-x-hidden">
    <mat-card-content style="min-height: 90vh;" fxLayout="row" fxLayout.lt-md="column">
      <div fxFlex="50%">
        <div fxLayout="column" fxLayoutAlign="center center" class="min-h-full" *ngIf="parametrosLogin">
          <ng-template [ngIf]="show === 'inicio'">
            <div class="w-96">
              <form class="w-32" fxFlex fxLayout="column" [formGroup]="loginForm">
                <div class="text-center">
                  <h2 class="uppercase text-2xl font-sans font-extrabold">Iniciar sesión</h2>
                </div>
                <mat-form-field appearance="outline" class="mb-3 mt-3">
                  <mat-label *ngIf="parametrosLogin.LOGINTYPE.LOGINTYPE === 'email'">Ingrese su email</mat-label>
                  <mat-label *ngIf="parametrosLogin.LOGINTYPE.LOGINTYPE === 'doc'">Ingrese su número de documento
                  </mat-label>
                  <input type="text" matInput formControlName="username" required autocomplete="username">
                  <mat-hint *ngIf="parametrosLogin.LOGINTYPE.LOGINTYPE === 'email'"> Nunca compartiremos su correo
                    electrónico
                    con nadie más.
                  </mat-hint>
                  <mat-hint *ngIf="parametrosLogin.LOGINTYPE.LOGINTYPE === 'doc'"> Nunca compartiremos su información
                    con
                    nadie
                    más.
                  </mat-hint>
                  <mat-error
                    *ngIf="loginForm.controls.username.hasError('email') && !loginForm.controls.username.hasError('required')">
                    Por favor, ingrese una dirección de email valida.
                  </mat-error>
                  <mat-error
                    *ngIf="loginForm.controls.username.hasError('required') && parametrosLogin.LOGINTYPE.LOGINTYPE === 'email'">
                    El email es <strong>requerido</strong>
                  </mat-error>
                  <mat-error
                    *ngIf="loginForm.controls.username.hasError('required') && parametrosLogin.LOGINTYPE.LOGINTYPE === 'doc'">
                    El número de documento es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Contraseña</mat-label>
                  <input #passInput type="password" matInput autocomplete="current-password" formControlName="password"
                    id="password">
                  <mat-icon *ngIf="passInput.type === 'password'" style="cursor: pointer;" matSuffix
                    (click)="onEyePasswordClick()">
                    visibility
                  </mat-icon>
                  <mat-icon *ngIf="passInput.type === 'text'" style="cursor: pointer;" matSuffix
                    (click)="onEyePasswordClick()">
                    visibility_off
                  </mat-icon>
                </mat-form-field>
                <div fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="center">
                  <button mat-raised-button type="submit" color="primary" (click)="iniciarSesion()"
                    [disabled]="!loginForm.valid">
                    <mat-icon>login</mat-icon> Ingresar
                  </button>
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <button mat-button type="button" (click)="changeVisual('forgot')"
                      class="bg-yellow-400 rounded-full p-2 text-white">
                      <mat-icon class="mr-2">key_off</mat-icon>Olvidé mi contraseña
                    </button>
                  </div>
                  <!-- NO SE APLICA EL REGISTRO PARA BANCO FAMILIAR -->
                  <!-- <button mat-button type="button" (click)="changeVisual('register')"
                    style="background: rgba(221, 241, 253, 1); border-radius: 12px; color:rgba(7, 71, 135, 1); white-space: normal">
                    ¿No eres usuario? <b>Regístrate aquí</b></button> -->
                </div>
              </form>
            </div>
          </ng-template>
          <ng-template [ngIf]="show === 'register'">
            <div fxFlex fxFlexOffset.gt-sm="5%">
              <h2>Regístrate</h2>
            </div>
            <app-register [minpassword]="parametrosLogin.MINPASSWORD.MINPASSWORD" (hide)="changeVisual('inicio')"
              [maxpassword]="parametrosLogin.MAXPASSWORD.MAXPASSWORD"></app-register>
          </ng-template>
          <ng-template [ngIf]="show === 'forgot'">
            <div class="text-center">
              <h2 class="uppercase text-2xl font-sans font-extrabold">Recuperar la cuenta</h2>
            </div>
            <app-olvidar-contrasenha (hide)="changeVisual('inicio')" [loginType]="parametrosLogin.LOGINTYPE.LOGINTYPE">
            </app-olvidar-contrasenha>
          </ng-template>
          <ng-template [ngIf]="show === 'recover'">
            <div fxFlex fxFlexOffset.gt-sm="20%">
              <h2>Cambiar contraseña</h2>
            </div>
            <app-recuperar-contrasenha [token]="recoveryToken" [parametrosIniciales]="parametrosLogin"
              (hide)="changeVisual('inicio')"></app-recuperar-contrasenha>
          </ng-template>
        </div>
      </div>
      <div fxFlex="50%" fxHide.lt-md class="bg-white">
        <div fxLayout="column">
          <div fxFlex fxLayout="column" fxLayoutAlign="center center">
            <div fxFlex fxFlexOffset="5">
              <img class="h-52" src="/assets/logo_senadis.svg" alt="SENADIS PRO">
            </div>
            <div fxFlex class="mb-10">
            </div>
          </div>
          <div fxFlex="calc(28em + 20px)" fxFlexOffset="20" class="lg:bg-zinc-500" fxLayout="column"
            fxLayoutAlign="center center" fxHide.lt-md>
            <img src="assets/images/login/Frame.png" alt="" style="position: relative; bottom: 180px" fxHide.lt-md>
            <div fxLayout="row" style="position: relative; top: -200px">
              <p class="uppercase text-2xl text-white font-mono font-bold">Portal de funcionarios</p>
            </div>
            <div fxLayout="row" class="mb-5">
              <a href="http://www.enterprisesolutions.com.py/" target="_blank">
                <img src="/assets/images/enterprise_white_logo.png" alt="Enterprise Solutions" class="w-56 h-16"
                  style="position: relative; top: -55px" fxHide.lt-md>
              </a>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="isLoading$ | async" class="loading-container">
  <mat-progress-spinner class="spinner" color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>