<h1 mat-dialog-title>Actualizar contraseña</h1>
<mat-dialog-content>
  <form [formGroup]="saveForm">
    <div fxLayout="column">
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Ingrese Contraseña</mat-label>
        <input type="password"
               matInput
               id="password" required autocomplete="new-password"
               formControlName="newPassword" name="password"/>
      </mat-form-field>
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Confirme Contraseña</mat-label>
        <input type="password" id="confirmPassword" matInput autocomplete="new-password"
               formControlName="confirmPassword" name="confirmPassword" required>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="changePassword()" cdkFocusInitial>Guardar</button>
  <button mat-raised-button mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
