<form [formGroup]="createUserForm">
  <div fxLayout="column" fxLayoutAlign="space-around space-between">
    <div fxFlex fxLayout="column" fxLayoutAlign="space-between">
      <mat-form-field fxFlex="45%" appearance="outline">
        <mat-label>Nombre</mat-label>
        <input type="text" matInput formControlName="nombre" required>
      </mat-form-field>
      <mat-form-field fxFlex="50%" appearance="outline">
        <mat-label>Apellido</mat-label>
        <input type="text" matInput formControlName="apellido" required>
      </mat-form-field>
    </div>
    <div fxFlex fxLayout="row" fxLayoutGap="1%">
      <mat-form-field fxFlex="45%" appearance="outline">
        <mat-label>Sexo</mat-label>
        <mat-select formControlName="sexo" required>
          <mat-option value="F">Femenino</mat-option>
          <mat-option value="M">Masculino</mat-option>
          <mat-option value="I">Indefinido</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Fecha de nacimiento</mat-label>
        <input matInput type="date" formControlName="fecha_nacimiento" required>
      </mat-form-field>
    </div>
    <mat-form-field fxFlex appearance="outline">
      <mat-label>Nombre de usuario</mat-label>
      <input type="text" matInput formControlName="name" autocomplete="username" required>
    </mat-form-field>
    <mat-form-field fxFlex appearance="outline">
      <mat-label>Ingrese su email</mat-label>
      <input id="user" type="email" aria-required="true" matInput autocomplete="email"
             formControlName="email" required>
      <mat-hint> Nunca compartiremos su correo electrónico con nadie más.</mat-hint>
      <mat-error
        *ngIf="createUserForm.controls.email.hasError('email') && !createUserForm.controls.email.hasError('required')">
        Por favor, ingrese una dirección de email valida.
      </mat-error>
      <mat-error *ngIf="createUserForm.controls.email.hasError('required')">
        El email es <strong>requerido</strong>
      </mat-error>
    </mat-form-field>
    <div fxLayout="row" fxLayoutGap="1%">
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Contraseña</mat-label>
        <input id="password" type="password" placeholder="Ingrese su contraseña" aria-required="true" matInput
               formControlName="password" autocomplete="new-password" required>
        <mat-error
          *ngIf="createUserForm.get('password').hasError('required') && createUserForm.get('password').touched">
          Debe ingresar una contraseña.
        </mat-error>
        <mat-error
          *ngIf="createUserForm.get('password').hasError('minlength') && createUserForm.get('password').touched">
          Debe introducir al menos {{minpassword}} caracteres.
        </mat-error>
        <mat-error
          *ngIf="createUserForm.get('password').hasError('maxlength') && createUserForm.get('password').touched">
          Debe introducir sólo hasta {{maxpassword}} caracteres.
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex appearance="outline">
        <mat-label>Confirmar contraseña</mat-label>
        <input id="passwordRep" type="password" aria-required="true" matInput
               formControlName="password_confirmation" autocomplete="new-password" required>
        <mat-error *ngIf="!createUserForm.get('password_confirmation').valid">
          Las contraseñas deben coincidir.
        </mat-error>
        <mat-error
          *ngIf="createUserForm.get('password_confirmation').hasError('minlength') && createUserForm.get('password_confirmation').touched">
          Debe introducir al menos {{minpassword}} caracteres.
        </mat-error>
        <mat-error
          *ngIf="createUserForm.get('password_confirmation').hasError('maxlength') && createUserForm.get('password_confirmation').touched">
          Debe introducir sólo hasta {{maxpassword}} caracteres.
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="center">
    <button type="submit" mat-raised-button color="primary"
            [disabled]="!createUserForm.valid || createUserForm.get('password').value !== createUserForm.get('password_confirmation').value "
            (click)="crearUsuario()">Crear
    </button>
    <div fxLayout="row" fxLayoutAlign="center">
      <hr fxFlex="60%">
    </div>
    <button mat-button type="button" (click)="[createUserForm.reset(),hide.emit(true)]"
            style="background: rgba(221, 241, 253, 1); border-radius: 12px; color:rgba(7, 71, 135, 1); white-space: normal">
      ¿Ya tienes usuario? <b>Inicia sesión aquí</b></button>
  </div>
</form>
