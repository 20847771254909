<div fxLayout="row" fxLayoutGap="2%" fxLayoutAlign.gt-sm="center center" fxLayout.lt-md="column">
  <button type="button" mat-raised-button color="primary" (click)="editar()"
          *ngIf="editable" [disabled]="!showEditarButton">Editar
  </button>
  <button type="button" mat-raised-button (click)="volver()"
          *ngIf="editable && !isModal">Volver
  </button>
  <button type="button" mat-raised-button (click)="cerrar()"
          *ngIf="editable && isModal">Cerrar
  </button>
  <button type="button" mat-raised-button color="primary" (click)="actualizar()"
          [disabled]="process || !valid"
          *ngIf="!editable">{{buttonTextActualizar}}
  </button>
  <button type="button" mat-raised-button (click)="cancelar()"
          *ngIf="!editable" [disabled]="process">Cancelar
  </button>
</div>
