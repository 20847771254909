import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ErrorService} from '../../util/services/error-service/error.service';
import {ToastrService} from 'ngx-toastr';
import {LoginService} from '../services/login.service';
import {FormStatusService} from '../../util/services/form-status/form-status.service';

@Component({
  selector: 'app-olvidar-contrasenha',
  templateUrl: './olvidar-contrasenha.component.html',
  styleUrls: ['./olvidar-contrasenha.component.sass']
})
export class OlvidarContrasenhaComponent implements OnInit {

  forgotPassForm: FormGroup;
  @Output() hide: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() loginType: string;

  constructor(private fB: FormBuilder, private loginService: LoginService,
              private fS: FormStatusService,
              private errorService: ErrorService, private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.loadForm();
  }

  loadForm(): void {
    switch (this.loginType) {
      case 'email':
        this.forgotPassForm = this.fB.group({
          email: [undefined, [Validators.required, Validators.email]]
        });
        break;
      case 'doc':
        this.forgotPassForm = this.fB.group({
          doc: [undefined, [Validators.required]]
        });
        break;
    }
  }

  enviarEmail() {
    const jsonData = this.fS.getJsonFromForm(this.forgotPassForm);
    this.loginService.enviarSolicitudCambioContrasenha(jsonData).subscribe((response: any) => {
      if (response.success) {
        this.toastr.success(response.data, 'Correo enviado correctamente');
      }

    }, err => {
      this.errorService.handleError(err);
    }, () => {
      this.hide.emit(true);
    });
  }

}
