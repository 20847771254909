<form [formGroup]="recuperarForm">
  <div fxLayout="column" fxLayoutAlign="space-around space-between" fxLayoutGap="8%">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Dirección de email</mat-label>
      <input matInput type="email" autocomplete="email" required
             formControlName="email">
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Contraseña nueva</mat-label>
      <input id="password" matInput type="password" required
             formControlName="password" autocomplete="new-password">
      <mat-hint>
        La contraseña debe tener una longitud mínima de {{parametrosIniciales.MINPASSWORD.MINPASSWORD}} caracteres
        y un maximo de {{parametrosIniciales.MAXPASSWORD.MAXPASSWORD}} caracteres.
      </mat-hint>
      <mat-error
        *ngIf="recuperarForm.get('password').hasError('minlength') && recuperarForm.get('password').touched">
        Debe introducir al menos {{parametrosIniciales.MINPASSWORD.MINPASSWORD}} caracteres.
      </mat-error>
      <mat-error
        *ngIf="recuperarForm.get('password').hasError('maxlength') && recuperarForm.get('password').touched">
        Debe introducir sólo hasta {{parametrosIniciales.MAXPASSWORD.MAXPASSWORD}} caracteres.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Repetir contraseña nueva</mat-label>
      <input matInput type="password" autocomplete="new-password"
             formControlName="password_confirmation" required>
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="center">
      <hr fxFlex="60%">
    </div>
    <div fxLayout="row" fxLayoutGap="2%">
      {{recuperarForm.errors}}
      <button fxFlex type="submit" mat-raised-button color="primary" (click)="actualizarPassword()"
              [disabled]="!recuperarForm.valid">Actualizar
      </button>
    </div>
  </div>
</form>
