import { environment } from './../../../../environments/environment';
import { ErrorService } from 'src/app/util/services/error-service/error.service';
import { ToastrService } from 'ngx-toastr';
import { FormStatusService } from 'src/app/util/services/form-status/form-status.service';
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ParametrosData } from 'src/app/util/interfaces/parametros/parametros-data';
import { LocalStorageService } from 'src/app/util/services/local-storage/local-storage.service';
import { UserProfileService } from './services/user-profile.service';
import { Perfil, PerfilResponse, UsuarioPerfilResponse } from './interfaces/user-settings.interface';
import { User } from 'src/app/util/interfaces/user/user';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.sass']
})
export class UserSettingsComponent implements OnInit, AfterViewChecked {

  changePasswordForm: FormGroup;
  changeInfoForm: FormGroup;
  parametros: ParametrosData;
  usuario: User;
  perfil: Perfil;
  disabled = true; // control para el pass form;
  firstTabDisabled = true; // control para el primer tab;
  imageToShowDialog: any;
  currentSetting: string = 'Cuenta'; // Primer item default;
  file: any;
  configs: string[] = ['Cuenta', 'Contraseña'];

  constructor(private localStorage: LocalStorageService,
    private fS: FormStatusService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private userProfileService: UserProfileService,
    private errorService: ErrorService,
    private cdRef: ChangeDetectorRef,
    private store: Store<{ count: boolean }>,
    private dialogRef: MatDialogRef<UserSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.parametros = this.localStorage.getParametrosFromStorage();
    this.usuario = this.localStorage.getUserFromStorage();
    this.makeForm();
    setTimeout(() => { // delay para el change detection del spinner; entra en conflicto
      this.getPerfil();
    }, 100)
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  makeForm(): void {
    // Cambio de password
    this.changePasswordForm = this.formBuilder.group({
      email: { value: '', disabled: this.disabled },
      password_current: { value: '', disabled: this.disabled },
      password: [{ value: '', disabled: this.disabled }, [Validators.minLength(this.parametros.MINPASSWORD), Validators.maxLength(this.parametros.MAXPASSWORD)]],
      password_confirmation: [{ value: '', disabled: this.disabled }, [Validators.minLength(this.parametros.MINPASSWORD), Validators.maxLength(this.parametros.MAXPASSWORD)]],
    });
    // Cambio de otros datos;
    this.changeInfoForm = this.formBuilder.group({
      email: [{ value: '', disabled: this.firstTabDisabled }, Validators.required],
      documento: { value: null, disabled: true }
    });
  }

  getPerfil() {
    this.userProfileService.getPerfil()
      .subscribe((respuesta: PerfilResponse) => {
        this.perfil = respuesta.data;
        if (this.perfil.foto_usuario) {
          this.imageToShowDialog = environment.FILE_ENDPOINT + this.perfil.foto_usuario;
        }
      }, error => {
        this.errorService.handleError(error);
      }
      ).add(() => {
        this.changeInfoForm.get('documento').patchValue(this.perfil.documento_completo);
        this.changeInfoForm.get('email').patchValue(this.perfil.email);
      });
  }

  public editableSwitcher(estado: string) {
    // Caso: formlario primer tab;
    if (estado === 'first') {
      this.changeInfoForm = this.fS.cambiarEstado(this.changeInfoForm, this.firstTabDisabled, ['documento'], true);
      this.firstTabDisabled = !this.firstTabDisabled;
    } else {
      this.changePasswordForm = this.fS.cambiarEstado(this.changePasswordForm, this.disabled);
      this.disabled = !this.disabled;
      this.changePasswordForm.controls.email.setValue(undefined);
      this.changePasswordForm.controls.password_current.setValue(undefined);
      this.changePasswordForm.controls.password.setValue(undefined);
      this.changePasswordForm.controls.password_confirmation.setValue(undefined);
    }
  }

  validar() {
    if (!this.changePasswordForm.value.password && !this.changePasswordForm.value.password_confirmation) {
      this.changePasswordForm.controls.password_current.disable();
      this.changePasswordForm.controls.password.disable();
      this.changePasswordForm.controls.password_confirmation.disable();
      this.updateUsuario();
    } else {
      if (this.changePasswordForm.value.password === this.changePasswordForm.value.password_confirmation) {
        this.updateUsuario();
      } else {
        this.toastr.error('Las contraseñas no coinciden. Ingrese las contraseñas de nuevo', 'Cambio de Contraseña');
      }
    }
  }

  updateUsuario() {
    this.changePasswordForm.controls.password_current.enable();
    this.changePasswordForm.controls.password.enable();
    this.changePasswordForm.controls.password_confirmation.enable();
    const jsonData = this.fS.getJsonFromForm(this.changePasswordForm);
    this.userProfileService.actualizarContrasenia(jsonData)
      .subscribe((respuesta: UsuarioPerfilResponse) => {
        if (respuesta.success) {
          this.toastr.success('Contraseña cambiada exitosamente', 'Cambio de Contraseña');
          this.editableSwitcher('E');
        } else {
          this.toastr.error('Error al cambiar contraseña', 'Cambio de Contraseña');
        }
      }, error => {
        this.errorService.handleError(error);
      }
      );
  }

  volver() {
    this.dialogRef.close();
  }

  onListClick(config: string): void {
    this.currentSetting = config;
  }

  fileChangeEvent(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.file = (target.files as FileList)[0];
    const formData = new FormData();
    if (this.file) {
      formData.append('file', this.file, this.file.name);
      if (this.usuario.userData.foto_perfil) {
        formData.append('method', 'PUT');
      } else {
        formData.append('method', 'POST');
      }
      formData.append('id', this.usuario.userData.user_id.toString());
      formData.append('entity', 'App\\Http\\Domain\\Entities\\User');
      formData.append('record', 'foto_usuario');
      formData.append('folder', 'usuarios');
    }
    this.userProfileService.editarUsuarioFoto(formData)
      .subscribe((respuesta: any) => {
        if (respuesta.success) {
          this.toastr.success('Foto de usuario editada exitosamente', 'Edición de Usuario');
          this.getPerfil();
        } else {
          this.toastr.error('Error al editar foto de usuario', 'Edición de Usuario');
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.handleError(error);
      }
      ).add(() => {
      });
  }

  onEyePasswordClick(step: string): void {
    let typePassword: string;
    // quitar el ID del input que se clickea:
    // first: actual | second: nueva contrasenha | third: confirmacion
    if (step === 'first') typePassword = 'currentPassword'
    else if (step === 'second') typePassword = 'newPassword'
    else typePassword = 'confirmationPassword'
    // referencia del input
    let input = (<HTMLInputElement>document.getElementById(typePassword));
    let type = input.type;
    // change type of the selected input!
    type === 'password' ? input.type = 'text' : input.type = 'password';
  }


}