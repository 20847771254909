<div class="container">
  <div fxLayout="row" fxLayoutAlign="end start">
    <button (click)="volver()" mat-icon-button><mat-icon>close</mat-icon></button>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column">
    <div ngClass.gt-sm="border-right" fxFlex="35">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div class="img-circle">
          <!-- <img [src]="imageToShowDialog" alt="Foto de perfil" class="shadow" *ngIf="imageToShowDialog"> -->
          <img src="https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png" alt="Foto de perfil"
            class="shadow">
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <p class="user-name"> {{usuario.userData.nombre}} {{usuario.userData.apellido}} </p>
      </div>
      <mat-selection-list #listado [multiple]="false">
        <mat-list-option [selected]="listado && i == 0" *ngFor="let listado of configs, let i = index" [value]="listado"
          (click)="onListClick(listado)">
          {{listado}}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <ng-template [ngIf]="currentSetting === 'Cuenta'">
      <div style="padding: 2.3rem;" fxFlex>
        <form [formGroup]="changeInfoForm">
          <div fxLayout="row">
            <div fxFlex style="margin-bottom: 1.4rem;">
              <h2>Datos del usuario</h2>
              <mat-divider></mat-divider>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="1%" fxLayout.lt-md="column">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Correo</mat-label>
              <input formControlName="email" matInput type="text" id="correo">
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Documento</mat-label>
              <input formControlName="documento" matInput type="text" id="documento">
            </mat-form-field>
          </div>
          <!-- <div fxLayout="row" style="margin-top: 1.4rem;">
                        <app-botonera-edicion fxFlex [editable]="firstTabDisabled" [isModal]="true" 
                            (clickUpdateEvent)="validar()" 
                            [buttonTextActualizar]="'Guardar'" [controlarPermiso]="false"
                            (clickCancelEvent)="editableSwitcher('first')" (clickEditEvent)="editableSwitcher('first')" 
                            (clickCloseEvent)="volver()" [valid]="changeInfoForm.valid">
                        </app-botonera-edicion>
                      </div> -->
        </form>
      </div>
    </ng-template>

    <ng-template [ngIf]="currentSetting === 'Contraseña'">
      <div style="padding: 2.3rem;" fxFlex ngClass.lt-md="pad-query">
        <form [formGroup]="changePasswordForm">
          <div fxLayout="row">
            <div fxFlex style="margin-bottom: 1.4rem;">
              <h2>Cambiar Contraseña</h2>
              <mat-divider></mat-divider>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="1%" fxLayout.lt-md="column">
            <mat-form-field fxFlex appearance="outline">
              <input matInput type="email" placeholder="Ingrese su Email" aria-label="Search" formControlName="email">
            </mat-form-field>
            <mat-form-field fxFlex appearance="outline">
              <input #firstInput matInput id="currentPassword" type="password" placeholder="Contraseña Actual"
                aria-label="Search" formControlName="password_current">
              <mat-icon class="cursor-pointer" matSuffix *ngIf="!disabled && firstInput.type === 'password'"
                (click)="onEyePasswordClick('first')">
                visibility
              </mat-icon>
              <mat-icon class="cursor-pointer" matSuffix *ngIf="!disabled && firstInput.type === 'text'"
                (click)="onEyePasswordClick('first')">
                visibility_off
              </mat-icon>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="1%" fxLayout.lt-md="column">
            <mat-form-field fxFlex appearance="outline">
              <input #secondInput matInput id="newPassword" id="newPassword" type="password"
                placeholder="Nueva contraseña" aria-label="Search" formControlName="password">
              <mat-icon class="cursor-pointer" matSuffix *ngIf="!disabled && secondInput.type === 'password'"
                (click)="onEyePasswordClick('second')">
                visibility
              </mat-icon>
              <mat-icon class="cursor-pointer" matSuffix *ngIf="!disabled && secondInput.type === 'text'"
                (click)="onEyePasswordClick('second')">
                visibility_off
              </mat-icon>
              <mat-error
                *ngIf="changePasswordForm.get('password').hasError('minlength') && changePasswordForm.get('password').touched">
                Debe introducir al menos {{parametros.MINPASSWORD}} caracteres.
              </mat-error>
              <mat-error
                *ngIf="changePasswordForm.get('password').hasError('maxlength') && changePasswordForm.get('password').touched">
                Debe introducir sólo hasta {{parametros.MAXPASSWORD}} caracteres.
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex appearance="outline">
              <input #thirdInput matInput id="confirmationPassword" type="password" placeholder="Repita la contraseña"
                aria-label="Search" formControlName="password_confirmation">
              <mat-icon class="cursor-pointer" matSuffix *ngIf="!disabled && thirdInput.type === 'password'"
                (click)="onEyePasswordClick('third')">
                visibility
              </mat-icon>
              <mat-icon class="cursor-pointer" matSuffix *ngIf="!disabled && thirdInput.type === 'text'"
                (click)="onEyePasswordClick('third')">
                visibility_off
              </mat-icon>
            </mat-form-field>
          </div>
        </form>
        <div fxLayout="row" style="margin-top: 1.4rem;">
          <app-botonera-edicion fxFlex [editable]="disabled" [isModal]="true" (clickUpdateEvent)="validar()"
            [buttonTextActualizar]="'Guardar'" [controlarPermiso]="false" (clickCancelEvent)="editableSwitcher('C')"
            (clickEditEvent)="editableSwitcher('E')" (clickCloseEvent)="volver()" [valid]="changePasswordForm.valid">
          </app-botonera-edicion>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="currentSetting === 'Foto de perfil'">
      <div style="padding: 2.3rem;" fxFlex>
        <div fxLayout="row">
          <div fxFlex style="margin-bottom: 1.4rem;">
            <h2>Cambiar foto de perfil</h2>
            <mat-divider></mat-divider>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex>
            <input class="file-input" type="file" id="foto_perfil" (change)="fileChangeEvent($event)">
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>