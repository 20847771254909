import { UserSettingsComponent } from './header/user-settings/user-settings.component';
import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '../util/services/local-storage/local-storage.service';
import { Globals } from '../Globals';
import { User } from '../util/interfaces/user/user';
import { EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.sass']
})
export class BaseLayoutComponent implements OnInit, AfterViewChecked {


  isLoading$: Observable<boolean>;
  mode: `over`;
  usuario: User;
  hasBackdrop: `true`;

  isSmall: boolean;
  isMenuOpen: boolean;
  isDark = true;
  imageToShowDialog: string;

  constructor(
    private store: Store<{ count: boolean }>,
    private cdRef: ChangeDetectorRef,
    public localStorageService: LocalStorageService,
    private globals: Globals,
    @Inject(DOCUMENT) private document: Document,
    private render: Renderer2,
    private dialog: MatDialog,
    private authService: AuthService,
    private breakpointObserver: BreakpointObserver
  ) {
    // Detectar el Screen Size para el modo del sidebar!
    this.breakpointObserver.observe(["(max-width: 768px)"])
      .subscribe((result: BreakpointState) => result.matches ? this.isSmall = true : this.isSmall = false);
  }

  ngOnInit(): void {
    // this.getPreferredTheme();
    this.getUsuario();
    this.getImgUser();
  }

  getPreferredTheme(): void {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.switchMode(true);
    }
  }

  getUsuario(): void {
    this.usuario = this.localStorageService.getUserFromStorage();
  }

  getImgUser(): void {
    const profileSrc = this.localStorageService.getUserFromStorage().userData?.foto_perfil;
    if (profileSrc) {
      this.imageToShowDialog = environment.FILE_ENDPOINT + profileSrc;
    }
  }

  ngAfterViewChecked(): void {
    this.isLoading$ = this.store.select('count');
    this.cdRef.detectChanges();
  }

  getSystemVersion(): string {
    return this.globals.getSystemVersion();
  }

  // onDarkMode() {
  //   this.modeSwitched.emit(true);
  // }

  switchMode(isDark: boolean): void {
    const hostClass = (isDark) ? 'dark' : 'mat-typography';
    this.render.setAttribute(this.document.body, 'class', hostClass);
    this.isDark = !this.isDark;
  }

  openSettings(): void {
    const dialog = this.dialog.open(UserSettingsComponent, {
      autoFocus: false,
      width: '55vw',
      data: this.isLoading$
    })
  }

  logout(): void {
    this.authService.logout();
  }


}
