<div class="example-container">
  <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="shadow mat-elevation-z10">
    <!--/*style="background-color: #616161"*/-->
    <div fxLayout="row" fxLayoutAlign="center start">
      <button mat-icon-button (click)="snav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span fxLayout="row" fxLayoutAlign="center center" style="height: 40px" class="example-app-name">
      </span>
    </div>
    <!-- <button mat-icon-button title="Logo">
      <img height="100" width="100" style="transform: scale(2.5);"
        src="https://sistema.senadis.gov.py/logo_senadis.aded35e7ca3d1fa1833e.svg" alt="Logo Senadis">
    </button> -->


    <div style="width: 100px" *ngIf="localStorageService.authenticated">
      <div fxLayout="row" fxLayoutGap="2%">
        <!-- <button style="width: 40px" fxLayout="row" fxLayoutAlign="start start" mat-icon-button
          (click)="switchMode(isDark)">
          <mat-icon *ngIf="!isDark" style="font-size: 40px">nightlight_round</mat-icon>
          <mat-icon *ngIf="isDark" style="font-size: 40px">light_mode</mat-icon>
        </button> -->
        <button style="width: 40px" fxLayout="row" fxLayoutAlign="start start" mat-icon-button
          [matMenuTriggerFor]="menu">
          <img *ngIf="usuario.userData.foto_perfil !== null" class="inline-block h-10 w-10 rounded-full"
            [src]="'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'" alt="Foto de perfil" />
          <mat-icon *ngIf="usuario.userData.foto_perfil === null" style="font-size: 40px">account_circle</mat-icon>
          <!--widgets-->
        </button>
      </div>
      <mat-menu #menu="matMenu">
        <div fxLayout="row wrap" fxLayoutAlign="center center">

          <button style="width: 40px" fxLayout="row" fxLayoutAlign="start start" mat-icon-button>
            <mat-icon style="font-size: 50px; color: #D2D2D2">account_circle</mat-icon>
          </button>
          <div class="mt-3" fxFlex="100" fxLayout="column" fxLayoutAlign="center center" style="height: 80px">
            <div class="text-black text-lg font-bold font-sans">{{usuario?.userData.nombre}}
              {{usuario?.userData.apellido}}</div>
            <div class="font-semibold text-sm inline-block py-1 px-3 m-2 rounded-full bg-blue-700 text-white">
              Usuario: {{usuario?.userName}}
            </div>
          </div>

        </div>

        <button mat-menu-item (click)="openSettings()">
          <mat-icon color="primary">verified_user
            <span style="margin-left: 6px; border-right: 1px solid #D2D2D2"></span>
          </mat-icon>
          <span>Cuenta</span>
        </button>

        <button mat-menu-item (click)="logout()">
          <mat-icon color="primary">logout
            <span style="margin-left: 6px; border-right: 1px solid #D2D2D2"></span>
          </mat-icon>
          <span>Salir</span>
        </button>
        <!--        <button mat-menu-item (click)="openConfiguracionModal()">-->
        <!--          <mat-icon color="primary">settings_suggest-->
        <!--            <span style="margin-left: 6px; border-right: 1px solid #D2D2D2"></span>-->
        <!--          </mat-icon>-->
        <!--          <span>Configuración</span>-->
        <!--        </button>-->
        <!--        <button mat-menu-item (click)="logout()"> &lt;!&ndash; [routerLink]="'/login'" &ndash;&gt;-->
        <!--          <mat-icon color="primary">input-->
        <!--            <span style="margin-left: 6px; border-right: 1px solid #D2D2D2"></span>-->
        <!--          </mat-icon>-->
        <!--          <span>Salir</span>-->
        <!--        </button>-->
      </mat-menu>
    </div>


  </mat-toolbar>

  <mat-drawer-container class="example-sidenav-container" [hasBackdrop]="false">
    <!--<mat-drawer #snav [ngClass.lt-md]="'mat-sidenav-sm'" [ngClass.gt-md]="'mat-sidenav-bg'" mode="side">-->
    <mat-drawer #snav [mode]="isSmall ? 'over' : 'side'" [opened]="isMenuOpen" [class.menu-open]="isMenuOpen"
      class="shadow1 mat-elevation-z10 bg-white dark:bg-gray-700">
      <div fxLayout="column" fxLayoutAlign="space-between" style="height: 100%"
        *ngIf="localStorageService.authenticated">
        <div fxFlex="90">
          <!--          <mat-accordion multi>-->
          <!--            <mat-expansion-panel [expanded]="true" class="mat-elevation-z0">-->
          <!--              <mat-expansion-panel-header-->
          <!--                                          (click)="$event.stopPropagation()"-->
          <!--                                          style="border-color: #FFFFFF;">-->
          <!--                <mat-panel-title>-->
          <!--                  Administración-->
          <!--                </mat-panel-title>-->
          <!--                <mat-divider></mat-divider>-->
          <!--              </mat-expansion-panel-header>-->

          <!--              <mat-nav-list>-->
          <!--                <mat-divider></mat-divider>-->
          <!--                <mat-list-item>-->
          <!--                  <mat-icon mat-list-icon>people</mat-icon>-->
          <!--                  <a matLine [routerLink]="['/administracion/personas']">Personas</a>-->
          <!--                </mat-list-item>-->
          <!--                <mat-divider></mat-divider>-->
          <!--                <mat-list-item>-->
          <!--                  <mat-icon mat-list-icon>admin_panel_settings</mat-icon>-->
          <!--                  <a matLine [routerLink]="['/administracion/usuarios']">Usuarios</a>-->
          <!--                </mat-list-item>-->
          <!--                <mat-divider></mat-divider>-->
          <!--                <mat-list-item>-->
          <!--                  <mat-icon mat-list-icon>privacy_tip</mat-icon>-->
          <!--                  <a matLine [routerLink]="['/administracion/roles']">Roles</a>-->
          <!--                </mat-list-item>-->
          <!--                <mat-divider></mat-divider>-->
          <!--                <mat-list-item>-->
          <!--                  <mat-icon mat-list-icon>gavel</mat-icon>-->
          <!--                  <a matLine [routerLink]="['/administracion/auditorias']">Auditorías</a>-->
          <!--                </mat-list-item>-->
          <!--                <mat-divider></mat-divider>-->
          <!--                <mat-list-item>-->
          <!--                  <mat-icon mat-list-icon>grid_view</mat-icon>-->
          <!--                  <a matLine [routerLink]="['/administracion/parametros-sistema']">Parámetros del Sistema</a>-->
          <!--                </mat-list-item>-->

          <!--              </mat-nav-list>-->

          <!--            </mat-expansion-panel>-->
          <!--          </mat-accordion>-->


          <!--          <mat-nav-list>-->
          <!--            <mat-divider></mat-divider>-->
          <!--            <mat-list-item>-->
          <!--              <mat-icon>poll</mat-icon>-->
          <!--              <a matLine [routerLink]="['/parametricos']">Paramétricos</a>-->
          <!--            </mat-list-item>-->
          <!--            <mat-divider></mat-divider>-->
          <!--          </mat-nav-list>-->

          <mat-nav-list class="dark:bg-gray-700 w-full">
            <mat-list-item class="dark:text-white" routerLinkActive="active" [routerLink]="['/perfil-usuario']">
              <mat-icon>person</mat-icon>
              <a matLine> Usuario </a>
            </mat-list-item>
            <mat-divider></mat-divider>
          </mat-nav-list>

          <!--          <mat-nav-list>-->
          <!--            <mat-list-item>-->
          <!--              <mat-icon>savings</mat-icon>-->
          <!--              <a matLine routerLinkActive="active" [routerLink]="['/perfil-usuario/bonificaciones']"> Bonificaciones </a>-->
          <!--            </mat-list-item>-->
          <!--            <mat-divider></mat-divider>-->
          <!--          </mat-nav-list>-->

          <!--<mat-nav-list>
            <mat-list-item class="dark:text-white" routerLinkActive="active" [routerLink]="['/perfil-usuario/legajos']">
              <mat-icon>dynamic_form</mat-icon>
              <a matLine> Legajos </a>
            </mat-list-item>
            <mat-divider></mat-divider>
          </mat-nav-list>
-->
          <!-- <mat-nav-list>
            <mat-list-item class="dark:text-white" routerLinkActive="active" [routerLink]="['/perfil-usuario/permisos']">
              <mat-icon>event_note</mat-icon>
              <a matLine > Permisos </a>
            </mat-list-item>
            <mat-divider></mat-divider>
          </mat-nav-list> -->

          <!-- <mat-nav-list>
            <mat-list-item class="dark:text-white" routerLinkActive="active" [routerLink]="['/perfil-usuario/marcaciones']">
              <mat-icon>timer</mat-icon>
              <a matLine > Marcaciones </a>
            </mat-list-item>
            <mat-divider></mat-divider>
          </mat-nav-list> -->

          <!-- <mat-nav-list>
            <mat-list-item class="dark:text-white" routerLinkActive="active" [routerLink]="['/perfil-usuario/multas']">
              <mat-icon>gavel</mat-icon>
              <a matLine > Multas </a>
            </mat-list-item>
            <mat-divider></mat-divider>
          </mat-nav-list> -->

          <!-- <mat-nav-list>
            <mat-list-item class="dark:text-white" routerLinkActive="active" [routerLink]="['/perfil-usuario/horarios']">
              <mat-icon>schedule</mat-icon>
              <a matLine > Horarios </a>
            </mat-list-item>
            <mat-divider></mat-divider>
          </mat-nav-list> -->

          <!-- <mat-nav-list>
            <mat-list-item class="dark:text-white" routerLinkActive="active" [routerLink]="['/perfil-usuario/evaluaciones']">
              <mat-icon>description</mat-icon>
              <a matLine > Evaluaciones </a>
            </mat-list-item>
            <mat-divider></mat-divider>
          </mat-nav-list> -->

          <!--          <mat-nav-list>-->
          <!--            <mat-list-item>-->
          <!--              <mat-icon>paid</mat-icon>-->
          <!--              <a matLine routerLinkActive="active" [routerLink]="['/perfil-usuario/liquidaciones']"> Liquidaciones </a>-->
          <!--            </mat-list-item>-->
          <!--            <mat-divider></mat-divider>-->
          <!--          </mat-nav-list>-->

          <!--          <mat-nav-list>-->
          <!--            <mat-list-item>-->
          <!--              <mat-icon>holiday_village</mat-icon>-->
          <!--              <a matLine routerLinkActive="active" [routerLink]="['/perfil-usuario/vacaciones']"> Vacaciones </a>-->
          <!--            </mat-list-item>-->
          <!--            <mat-divider></mat-divider>-->
          <!--          </mat-nav-list>-->

        </div>
        <!--<mat-nav-list>-->
        <div style="margin: 5px" class="dark:text-white">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <span>Versión: {{getSystemVersion()}}</span>
          </div>
        </div>
        <!--</mat-nav-list>-->
      </div>

    </mat-drawer>

    <mat-drawer-content class="bg-gray-200 dark:bg-gray-900"> <!--#d2d2d2-->

      <!--<button mat-button color="primary" (click)="active()">
        Active
      </button>-->
      <div class="main-container">
        <router-outlet></router-outlet>
      </div>
      <!--      <app-footer></app-footer>-->
    </mat-drawer-content>
  </mat-drawer-container>
</div>


<div *ngIf="isLoading$ | async" class="loading-container">
  <mat-progress-spinner class="spinner" color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>
