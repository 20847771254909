import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../services/login.service';
import {ErrorService} from '../../util/services/error-service/error.service';
import {ToastrService} from 'ngx-toastr';
import {DatePipe} from '@angular/common';
import {FormStatusService} from '../../util/services/form-status/form-status.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.sass'],
  providers: [DatePipe]
})
export class RegisterComponent implements OnInit {

  createUserForm: FormGroup;
  @Input() minpassword: string;
  @Input() maxpassword: string;
  @Output() hide: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private formBuilder: FormBuilder,
              private loginService: LoginService,
              private fS: FormStatusService,
              private errorService: ErrorService,
              private toastr: ToastrService,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.loadForm();
  }

  loadForm() {
    this.createUserForm = this.formBuilder.group({
      nombre: [undefined, [Validators.required]],
      apellido: [undefined, [Validators.required]],
      name: [undefined, [Validators.required]],
      email: [undefined, [Validators.required, Validators.email]],
      password: [undefined, [Validators.required, Validators.minLength(+this.minpassword), Validators.maxLength(+this.maxpassword)]],
      sexo: [undefined, Validators.required],
      fecha_nacimiento: [undefined, Validators.required],
      password_confirmation: [undefined, [Validators.required, Validators.minLength(+this.minpassword), Validators.maxLength(+this.maxpassword)]],
    });
    this.onChange();
  }

  crearUsuario() {
    this.createUserForm.get('fecha_nacimiento').setValue(this.datePipe.transform(this.createUserForm.value.fecha_nacimiento, 'yyyy-MM-dd'));
    if (this.createUserForm.controls.password.value === this.createUserForm.controls.password_confirmation.value) {
      const jsonData = this.fS.getJsonFromForm(this.createUserForm);
      this.loginService.registrarUsuario(jsonData).subscribe((respuesta: any) => {
        this.toastr.success('Usuario registrado exitosamente', 'Registrar Usuario');
      }, err => {
        this.errorService.handleError(err);
      }, () => {
        this.hide.emit(true);
      });
    } else {
      this.toastr.warning('Las contraseñas no coinciden', 'Atención');
    }
  }

  onChange() {
    this.createUserForm.get('password_confirmation').valueChanges.subscribe(val => {
      const matched: boolean = this.createUserForm.controls.password.value === val;
      if (matched) {
        this.createUserForm.controls.password_confirmation.setErrors(null);

      } else {
        this.createUserForm.controls.password_confirmation.setErrors({
          notUnique: false
        });
      }
    });
  }
}
