import {Injectable} from '@angular/core';
import {AuthService} from './services/auth.service';
import {Router} from '@angular/router';
import {NgxPermissionsService} from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private authService: AuthService, private router: Router,
              private ngxPermission: NgxPermissionsService) {
  }

  canActivate(): Promise<boolean> {
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['/login']);
      return Promise.reject(false);
    }
    const loadedPer = this.ngxPermission.getPermissions();
    if (Object.keys(loadedPer).length === 0) {
      return this.authService.cargarPermisos();
    } else {
      return Promise.resolve(true);
    }
  }
}
