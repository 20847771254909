import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { LoginComponent } from './login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Globals } from './Globals';
import { AuthTokenInterceptor } from './auth/interceptors/auth-token.interceptor';
import { StoreModule } from '@ngrx/store';
import { counterReducer } from './util/store/counter.reducer';
import { BaseLayoutModule } from './base-layout/base-layout.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { LottieModule } from 'ngx-lottie';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { RegisterComponent } from './login/register/register.component';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { OlvidarContrasenhaComponent } from './login/olvidar-contrasenha/olvidar-contrasenha.component';
import { ForzarCambioContrasenhaComponent } from './login/forzar-cambio-contrasenha/forzar-cambio-contrasenha.component';
import { MatDialogModule } from '@angular/material/dialog';
import { RecuperarContrasenhaComponent } from './login/recuperar-contrasenha/recuperar-contrasenha.component';
import { MatIconModule } from '@angular/material/icon';
export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    OlvidarContrasenhaComponent,
    ForzarCambioContrasenhaComponent,
    RecuperarContrasenhaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    BaseLayoutModule,
    NgxPermissionsModule.forRoot(),
    FlexLayoutModule,
    StoreModule.forRoot({ count: counterReducer }),
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatDividerModule,
    LottieModule.forRoot({ player: playerFactory }),
    SweetAlert2Module.forRoot(),
    MatSelectModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatDialogModule,
    MatIconModule

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    },
    Globals
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
