import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BotoneraEdicionComponent } from './botonera-edicion.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';



@NgModule({
    declarations: [
        BotoneraEdicionComponent
    ],
    exports: [
        BotoneraEdicionComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatButtonModule
    ]
})
export class BotoneraEdicionModule { }
