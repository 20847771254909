import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, SESSION_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Observable} from 'rxjs';
import {User} from '../../interfaces/user/user';
import {ParametrosData} from '../../interfaces/parametros/parametros-data';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  USER_KEY = 'user';
  TOKEN_KEY = 'token';
  REFRESH_TOKEN_KEY = 'refresh_token';

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {
  }

  public storeOnLocalStorage(user: any): void {
    // this.storage.set(STORAGE_USUARIO_KEY, user);
    if (user !== null) {
      user.password = '';
      localStorage.setItem(this.USER_KEY, JSON.stringify(user));
      localStorage.setItem(this.TOKEN_KEY, user.userData.access_token);
      localStorage.setItem(this.REFRESH_TOKEN_KEY, user.userData.refreshToken);
    }
  }

  public setToken(newToken: string): void {
    localStorage.setItem(this.TOKEN_KEY, newToken);
  }

  public setRefreshToken(newRefreshToken: string): void {
    localStorage.setItem(this.REFRESH_TOKEN_KEY, newRefreshToken);
  }

  public getUserFromStorage(): any {
    return JSON.parse(<string>localStorage.getItem(this.USER_KEY));
  }

  public cleanStorage(): void {
    localStorage.clear();
  }

  public getTokenFromStorage(): string {
    return <string>localStorage.getItem(this.TOKEN_KEY);
  }

  public getRefreshTokenFromStorage(): string {
    return <string>localStorage.getItem(this.REFRESH_TOKEN_KEY);
  }

  public storeParametros(parametros: any[]) {
    localStorage.setItem('parametros', JSON.stringify(parametros));
  }

  public getParametrosFromStorage(): ParametrosData {
    return JSON.parse(<string>localStorage.getItem('parametros'));
  }

  public removeToken(): void {
    localStorage.clear();
  }

  public storeSearch(search: any, searchFrom: string): void {
    this.clearStoreSearch();
    localStorage.setItem('search_from', searchFrom);
    localStorage.setItem('search', JSON.stringify(search.value));
  }

  public getStoreSearchFrom(): any {
    return localStorage.getItem('search_from');
  }

  public getStoreSearch(): any {
    return JSON.parse(<string>localStorage.getItem('search'));
  }

  public clearStoreSearch(): void {
    localStorage.removeItem('search');
  }

  public setLoading(isLoading: boolean): void {
    this.storage.set('isLoading', isLoading);
  }

  public getLoading(): Observable<boolean> {
    return this.storage.get('isLoading');
  }

  public get authenticated(): boolean {
    // const expiration = parseInt(localStorage.getItem('expiration'), 10);
    const user = JSON.parse(localStorage.getItem('user')) as User;
    // if (!(expiration && expiration > 0)) return false;
    // @ts-ignore
    if (!(user?.userData.expires_in)) return false;
    // tslint:disable-next-line:variable-name
    // @ts-ignore
    // tslint:disable-next-line:variable-name
    var t = new Date();
    t.setSeconds(user.userData.expires_in);
    return !(new Date() > t);
  }

  public storeData(name: string, value: any) {
    localStorage.setItem(name, value);
  }

  public getStoredData(name: string): any {
    return localStorage.getItem(name);
  }
}
