import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {BaseLayoutComponent} from './base-layout/base-layout.component';
import {AuthGuardService} from './auth/auth-guard.service';

const routes: Routes = [
  {
    path: 'login', component: LoginComponent
  },
  {
    path: '', component: BaseLayoutComponent,
    children: [
      // {
      //   path: 'administracion',
      //   canActivate: [AuthGuardService],
      //   loadChildren: () => import('./administracion/administracion.module').then(m => m.AdministracionModule)
      // },
      // {
      //   path: 'parametricos',
      //   canActivate: [AuthGuardService],
      //   loadChildren: () => import('./parametricos/parametricos.module').then(m => m.ParametricosModule)
      // },
      {
        path: 'perfil-usuario',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./perfil-user/perfil-user.module').then(m => m.PerfilUserModule)
      },

    ]
    , canActivate: [AuthGuardService],
  },
  {path: '**', redirectTo: 'login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      useHash: true
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
